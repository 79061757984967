import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import("../views/login.vue")
  },
  {
    path: "/asignaciones/:persona_id?",
    component: () => import("../views/asignaciones.vue")
  },
  {
    path: "/personas",
    component: () => import("../views/personas.vue")
  },
  {
    path: '/cargos/:id?',
    component: () => import("../views/cargos.vue")
  },
  {
    path: '/items/:item_id?',
    component: () => import('../views/items.vue')
  },
  {
    path: '/rastreos',
    component: () => import('../views/rastreos.vue')
  },
  {
    path: '/reporte-items-une',
    component: () => import('../views/reporte-items-une.vue')
  },
  {
    path: '/reporte-historicos-persona',
    component: () => import('../views/reporte-historicos-persona.vue')
  },
  {
    path: '/avisos/:id?',
    component: () => import("../views/avisos.vue")
  },
  {
    path: '/verificaciones',
    component: () => import("../views/verificaciones.vue")
  },
  {
    path: '/inactivos',
    component: () => import("../views/inactivos.vue")
  },
  {
    path: '/edp-obra',
    component: () => import("../views/edp-obra.vue")
  },
]

const router = new VueRouter({
  mode: 'history',
  base: "/inventario",
  routes
})

export default router
