import Vue from 'vue'
import App from './App.vue'
import router from './router'


// CSS Global
import './assets/main.css';


// biblioteca Moment.js para parseo fechas / horas
const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), { moment });


// Registro global de Vue-Bootstrap y sus íconos
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


// Variable global this.$api y this.$api_conpax para acceso a API local
// y general (Conpax)
import axios from 'axios'

const url_api = Vue.config.devtools ?
    "http://localhost:1092/inventario/v1" :
    "https://api.conpax.cl/inventario/v1"
Vue.prototype.$url_api = url_api
Vue.prototype.$api = axios.create({
  baseURL: url_api
})

const url_base = Vue.config.devtools ?
    "http://localhost:2086/api/v1" :
    "https://api.conpax.cl/api/v1"
Vue.prototype.$url_base = url_base
Vue.prototype.$api_conpax = axios.create({
  baseURL: url_base
})


// Biblioteca de validacion de controles en formularios
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


// Barra de progreso común para cambios de página (ruta) y peticiones http
import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
  thickness: '10px',
  autoFinish: false
})


// Combo con auto-completado
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)


// Componentes propias importadas globalmente
Vue.component('fecha', () => import('./controls/fecha.vue'))


// Permite usar computed asincronico
import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)


// variables / funciones globales
import Globales from './globales.coffee'
Vue.use(Globales, {})


//constructor principal
new Vue({
  router,
  data: {
    overlay: false,
    tecnico_nombre: "usuario",
    tecnico_id: null,
    companias: [],
    unes: [],
    unes_vigentes: [],
    personas: [],
    personas_vigentes: []
  },
  render: h => h(App)
}).$mount('#app')
